import React, {Fragment, useEffect, useState} from 'react';
import styled from "styled-components";
import {makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import preloader from "./../../../images/pay_preloader.svg";
import reloadIcon from "./../../../images/reloadIcon.svg";
import BackImage from "./../../../images/arrow_left.svg"
import _ from 'underscore';
import {PayCardsModal} from "../ModalPayCards";
import {request} from "../../../utils/request";
import Modal from "react-modal";
import {getWallets, getWalletLimit} from "../../../actions/ProfileActions";
import {useDispatch, useSelector} from "react-redux";
import {MAIN_COLOR} from "../../../utils/constants";
import {SAVE_ORDER, SAVE_ORDER_REQUEST} from "../../../actions/MenuActions";
import {deliveryCycleInfoMessage} from "../../../actions/ModalActions";
import cartBack from "../../../images/cart-back.svg";

const useStyles = makeStyles((theme: any) => ({
    typography: {
        padding: theme.spacing(2),
    },
}));

const namePayType = (index: any) => {
    let name = "";

    switch (index) {
        case "1": name = "Наличными";
            break;
        case "8": name = "Картой курьеру";
            break;
        case "16": name = "Оплатить онлайн";
            break;
        case "32": name = "Оплатить баллами";
            break;
    }

    return name;
};

export const SimplePopover = ({day, getAccountsForDate, orderId, payStatus, priceResult, paymentStatus, restInfo, products, regOrder, activePeriod, isMobile}: any) => {
    const dispatch = useDispatch();

    const classes = useStyles();
    const [openPopover, setOpenPopover] = useState(false);
    const [cards, setCards] = useState([]);
    const [reloadStatus, setReloadStatus] = useState(false);
    const [payProcessing, setPayProcessing] = useState({});
    const [openModalAdd, setOpenModalAdd] = useState(false);
    const [checkedRadio, setRadio] = useState('');
    const [checkedAmount, setAmount] = useState('');
    const [dayLimit, setLimit] = useState('');
    const [currentWallet, setWallet] = useState('');
    const [rotating, setRotate] = useState(0);

    const payCard = async (id: string, cardId: string = '') => {
        let response = await request("PaySystemService", "RegisterOrder",  {
            orders: [{id: id}],
            paysystem: {"id": 16},
            cardId: cardId
        });

        if(response.result === 1){
            setPayProcessing({
                ...payProcessing,
                [orderId]: true
            });

            let newWindow = window.open(response.data.formUrl);
        }
    };

    const getPayCard = async (id: string) => {
        let response = await request("PaySystemService", "GetCards", { account: id });

        if (response.result === 1) {
            if (_.size(response.data.cards) !== 0) {
                setCards(response.data.cards);
            }
        }
    };

    const removeFromCard = async (cardId: string, restId: string, paysystemId: number) => {

        let response = await request("PaySystemService", "DeleteCard", {
            cardId,
            account: restId,
            paysystem: {id: paysystemId}
        });

        if (response.result === 1) {
            dispatch(deliveryCycleInfoMessage('remove_from_card'));
            await getPayCard(orderId);
        }

        // --------------------------

        let response2 = await request("PaySystemService", "GetCards", { account: orderId });

        if (response2.result === 1) {
            setCards(response2.data.cards);
        }

    };

    const payWallet = async () => {
        dispatch({
            type: SAVE_ORDER_REQUEST,
        });

        let response = await request("DinnerController", "SaveOrder", {
            owner: {
                id: restInfo.id
            },
            // deliveryData: {
            //     time: day
            // },
            date: {dateString: day},
            paysystem: {
                id: 32
            },
            products: products,
            wallet: {
                id: currentWallet,
            },
            period: {id: activePeriod},
        });

        if(response.result === 1){
            await getAccountsForDate(day, activePeriod);

            dispatch({
                type: SAVE_ORDER,
            });
        }
    };

    const reloadStatusInterval = () => {
        setReloadStatus(true);
        setRotate(rotating + 360);
        getAccountsForDate(day, activePeriod);

        setTimeout(() => {
            setReloadStatus(false);
        }, 3000)
    };

    const { profile, activeCompany, wallets, walletPaid } = useSelector((store: any) => ({
        profile: store.profile.profile,
        activeCompany: store.profile.activeCompany,
        wallets: store.profile.wallets,
        walletPaid: store.profile.wallet_paid,
    }));

    const handleOpenModalAdd = (e: any) => {
        if (e) {
            e.stopPropagation();
        }
        setOpenModalAdd(!openModalAdd);
        setRadio('');

        let data = {
            clientId: profile.id,
            companyId: activeCompany.id,
            date: day,
            restId: restInfo.id,
        }

        if (!openModalAdd) {
            dispatch(getWallets(data));
        }

    };

    useEffect(() => {
        getPayCard(orderId);
    }, []);

    const id = openPopover ? 'simple-popover' : undefined;

    const handleRadio = (event: any, v: any) => {
        dispatch(getWalletLimit(v.id, day));
        setRadio(v.id);
        setAmount(v.amount);
        setLimit(v.limitAmountPerDay);
        setWallet(v.id);
    }

    return (
        <div>
            {_.some(payProcessing, (pay, keyPay) => {return orderId === keyPay}) ? (
                <Fragment>
                    <PanelPay>
                        <Button aria-describedby={id} color="primary">
                            <Text>Обновить статус платежа</Text>
                            <Preloader src={reloadIcon} onClick={reloadStatusInterval} style={{transform: `rotate(${rotating}deg`}} />
                            {/*<Tooltip title={"Обновить статус платежа"} placement="right">*/}
                            {/*</Tooltip>*/}
                        </Button>
                    </PanelPay>
                    {payStatus === "1" && reloadStatus && (
                        <PreloadBlock>
                            <Button aria-describedby={id} color="primary">
                                <Text2>Идёт процесс оплаты</Text2>
                                <Preloader src={preloader}/>
                            </Button>
                        </PreloadBlock>
                    )}
                </Fragment>
            ) : (
                <PanelPay>
                    <Button aria-describedby={id} color="primary">
                        <Bullet>
                            <BulletInner/>
                        </Bullet>
                        <Text>{isMobile ? 'Наличные' : 'Наличными (Выбран по умолчанию)'}</Text>
                        {/*<Preloader src={reloadIcon} onClick={() => setRotate(rotating + 360)} style={{transform: `rotate(${rotating}deg`}} />*/}
                    </Button>
                    {_.size(cards) === 0 ? (
                        <PayButtons>
                            <PayType onClick={() => {payCard(orderId)}}>{namePayType('16')}</PayType>
                            <PayType onClick={(e)=>{handleOpenModalAdd(e)}}>{namePayType('32')}</PayType>
                        </PayButtons>
                    ) : (
                        <PayButtons>
                            <PayCardsModal
                                orderId={orderId}
                                priceResult={priceResult}
                                cards={cards}
                                payCards={payCard}
                                namePayType={namePayType}
                                setOpenPopover={setOpenPopover}
                                restId={restInfo.id}
                                removeFromCard={() => removeFromCard}
                                isMobile={isMobile}
                            />
                            <PayType onClick={(e)=>{handleOpenModalAdd(e)}}>{namePayType('32')}</PayType>
                        </PayButtons>
                    )}
                </PanelPay>
            )}
            <Modal
                closeTimeoutMS={100}
                isOpen={openModalAdd}
                style={isMobile ? customStylesMobile : customStyles}
                overlayClassName={"Modifiers__Overlay"}
                onRequestClose={(e)=>{handleOpenModalAdd(e)}}>
                {isMobile ? (
                    <ModalWrapper onClick={(e)=>{e.stopPropagation()}}>
                        <ModalHeader>
                            <ModalBack onClick={(e)=>{handleOpenModalAdd(e)}}>
                                <img src={cartBack} alt="Закрыть"/>
                            </ModalBack>
                            <PaymentByPointsText>Оплата баллами</PaymentByPointsText>
                        </ModalHeader>
                        <WalletsForm>
                            <div style={{display: 'flex', flexDirection: 'column', paddingTop: 42, paddingLeft: 23, paddingRight: 23}}>
                                {(wallets.wallets && wallets.wallets.length === 0) && <p style={{
                                    fontFamily: "Roboto",
                                    fontWeight: 'normal',
                                    fontSize: 15,
                                    lineHeight: '18px',
                                    color: '#333333',
                                }}>
                                    Доступные кошельки отсутствуют
                                </p>}
                                {wallets.wallets && wallets.wallets.map((v: any, i: any) => {
                                    return (
                                        <WalletItem key={v.id} style={{marginBottom: 20}} onClick={(e) => handleRadio(e, v)}>
                                            <CustomInputBox>
                                                <CustomInput style={{backgroundColor: checkedRadio === v.id ? MAIN_COLOR : '#FFF'}} />
                                            </CustomInputBox>
                                            <WalletInfo>
                                                {v.name}. {+v.amount} баллов
                                            </WalletInfo>
                                        </WalletItem>
                                    )
                                })}
                            </div>
                            {(wallets.wallets && wallets.wallets.length !== 0) &&
                                <ModalFooter>
                                    <SubmitWallet
                                        onClick={() => payWallet()}
                                        disabled={
                                            !checkedRadio ||
                                            +priceResult > +checkedAmount ||
                                            (walletPaid && +priceResult > +dayLimit - walletPaid.paidSum && +dayLimit > 0)
                                        }
                                        style={{
                                            backgroundColor:
                                                !checkedRadio ||
                                                +priceResult > +checkedAmount ||
                                                (walletPaid && +priceResult > +dayLimit - walletPaid.paidSum && +dayLimit > 0) ?
                                                    '#ddd' : `${MAIN_COLOR}`,
                                        }}>
                                        <p style={{margin: 0, color: !checkedRadio ? '#999999' : `#ffffff`}}>
                                            {!checkedRadio ? 'Кошелёк не выбран' :
                                                +priceResult > +checkedAmount ? 'Недостаточно баллов для оплаты' :
                                                    (walletPaid && +priceResult > +dayLimit - walletPaid.paidSum && +dayLimit > 0) ? 'Превышен дневной лимит' : `Оплатить ${+priceResult} б.`}
                                        </p>
                                    </SubmitWallet>
                                </ModalFooter>
                            }
                        </WalletsForm>
                    </ModalWrapper>
                ) : (
                    <ModalWrapper onClick={(e)=>{e.stopPropagation()}}>
                        <P28>Оплата баллами</P28>
                        <WalletsForm>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                {(wallets.wallets && wallets.wallets.length === 0) && <p>Доступные кошельки отсутствуют</p>}
                                {wallets.wallets && wallets.wallets.map((v: any, i: any) => {
                                    return (
                                        <WalletItem key={v.id} style={{marginBottom: i === wallets.wallets.length - 1 ? 110 : 10}} onClick={(e) => handleRadio(e, v)}>
                                            <CustomInputBox>
                                                <CustomInput style={{backgroundColor: checkedRadio === v.id ? MAIN_COLOR : '#FFF'}} />
                                            </CustomInputBox>
                                            <WalletInfo>
                                                {v.name} (доступно <span style={{fontWeight: 'bold'}}>{+v.amount}</span> баллов)
                                            </WalletInfo>
                                        </WalletItem>
                                    )
                                })}
                            </div>
                            <CloseWallets onClick={(e)=>{handleOpenModalAdd(e)}}>
                                <BackImg src={BackImage}/>
                                Назад
                            </CloseWallets>
                            {(wallets.wallets && wallets.wallets.length !== 0) &&
                            <SubmitWallet
                                onClick={() => payWallet()}
                                disabled={
                                    !checkedRadio ||
                                    +priceResult > +checkedAmount ||
                                    (walletPaid && +priceResult > +dayLimit - walletPaid.paidSum && +dayLimit > 0)
                                }
                                style={{
                                    backgroundColor:
                                        !checkedRadio ||
                                        +priceResult > +checkedAmount ||
                                        (walletPaid && +priceResult > +dayLimit - walletPaid.paidSum && +dayLimit > 0) ?
                                            '#bbb' : `${MAIN_COLOR}`,
                                    cursor:
                                        !checkedRadio ||
                                        +priceResult > +checkedAmount ||
                                        (walletPaid && +priceResult > +dayLimit - walletPaid.paidSum && +dayLimit > 0) ?
                                            'default' : `pointer`
                                }}>
                                <p style={{margin: 0, paddingTop: 2}}>
                                    {!checkedRadio ? 'Кошелёк не выбран' :
                                        +priceResult > +checkedAmount ? 'Недостаточно баллов для оплаты' :
                                            (walletPaid && +priceResult > +dayLimit - walletPaid.paidSum && +dayLimit > 0) ? 'Превышен дневной лимит' : `Оплатить ${+priceResult} б.`}
                                </p>
                            </SubmitWallet>
                            }
                        </WalletsForm>
                    </ModalWrapper>
                )}
            </Modal>
        </div>
    );
};

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        padding: "15px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "auto",
        width: "100%",
    }
};

const customStylesMobile = {
    content: {
        top: 0,
        left: 0,
        right: "auto",
        bottom: "auto",
        padding: 0,
        backgroundColor: "transparent",
        border: "none",
        borderRadius: 0,
        cursor: "auto",
        width: "100%",
        height: "100%",
    }
};

const ModalWrapper = styled.div`
    width: 900px;
    min-height: 361px;
    margin: 0 auto;
    padding: 55px 65px;
	background: #fff;
	border-radius: 26px;
    overflow: hidden;
    
    @media (max-width: 992px) {
        width: 100%;
        min-height: 100%;
        height: 100%;
        padding: 0;
        border-radius: 0;
    }
`;

const Button = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-right: 12px;
    margin-bottom: 10px;
    
    @media (max-width: 992px) {
        margin-bottom: 12px;
    }
`;

const Text = styled.span`
    /*color: #1DD960;*/
    color: #fff;
    /*font-family: "SF Display";*/
    font-size: 12px;
    line-height: 14px;
    
    @media (max-width: 992px) {
        font-family: "Roboto", sans-serif;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: #263238;
    }
`;

const Text2 = styled(Text)`
    border: none;
`;

const PayType = styled.div`
    padding: 4px 0;
    cursor: pointer;
    width: calc((100% - 4px) / 2);
    background: #fff;
    color: #37474F;
    text-align: center;
    border-radius: 66px;
    border: 1px solid #37474F;
    font-size: 12px;
    line-height: 18px;
  
  @media (min-width: 992.01px) {
      &:hover{
        background: #37474F;
        color: #fff;
        border: 1px solid #fff;
      }
  }
  
  @media (max-width: 992px) {
    padding: 9px 0;
    width: calc((100% - 16px) / 2);
    background: #CDDC39;
    color: #263238;
    text-align: center;
    border-radius: 66px;
    border: 0;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 18px;
  }
`;

const Preloader = styled.img`
    width: 16px;
    height: 16px;
    margin-left: 8px;
    cursor: pointer;
    transition: 550ms ease-in-out;
    
    &:active{
      transform: rotate(360deg);
    }
`;

const PreloadBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 12px 0;
`;

const PanelPay = styled.div`
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*align-items: center;*/
`;

const Bullet = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border-radius: 66px;
    border: 1px solid #fff;
    
    @media (max-width: 992px) {
        margin-right: 6px;
        border: 2px solid #CDDC39;
    }
`;

const BulletInner = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: #fff;
    
    @media (max-width: 992px) {
        background: #CDDC39;
    }
`;

const PayButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const P28 = styled.p`
    margin-bottom: 32px;
    font-size: 28px;
    line-height: 34px;
    color: #263238;
`;

const WalletsForm = styled.div`
    position: relative;
    min-height: 185px;
    
    @media (max-width: 992px) {
        position: static;
        min-height: auto;
        height: calc(100% - 50px);
        padding-bottom: 60px;
        overflow-y: scroll;
    }
`;

const SubmitWallet = styled.button`
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 332px;
    height: 52px;
    background: ${MAIN_COLOR};
    border-radius: 6px;
    border: 0;
    outline: 0;
    font-size: 16px;
    line-height: 19px;
    color: #fff;
    cursor: pointer;
    
    @media (max-width: 992px) {
        position: static;
        bottom: auto;
        right: auto;
        max-width: 390px;
        width: 100%;
        height: 42px;
        border-radius: 666px;
        font-size: 15px;
        line-height: 18px;
        font-family: "Roboto", sans-serif;
        font-weight: 500;
    }
`;

const CloseWallets = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 200px;
    height: 52px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #333;
    outline: 0;
    font-size: 16px;
    line-height: 19px;
    justify-content: center;
    color: #333;
    cursor: pointer;
`;

const BackImg = styled.img`
    position: absolute;
    left: 13px;
    top: calc((100% - 10px) / 2);
    width: 12px;
    height: 10px;
`;

const WalletItem = styled.div`
    display: flex;
    
    @media (max-width: 992px) {
        align-items: center;
    }
`;

const CustomInputBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin-right: 12px;
    border-radius: 66px;
    border: 1px solid ${MAIN_COLOR};
`;

const CustomInput = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 66px;
    background: #fff;
    cursor: pointer;
`;

const WalletInfo = styled.p`
    display: inline-block;
    font-size: 18px;
    line-height: 22px;
    color: #333;
    cursor: pointer;
    
    @media (max-width: 992px) {
        width: calc(100% - 34px);
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        line-height: 18px;
        color: #333333;
        margin: 0;
    }
`;

const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 3px 4.5px;
`

const ModalBack = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 44px;
	height: 44px;
`;

const PaymentByPointsText = styled.p`
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #111111;
    margin: 0;
    text-align: center;
    width: calc(100% - 88px);
    height: 18px;
`;

const ModalFooter = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    padding: 9px 12px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
`
