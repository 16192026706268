import { reducer as formReducer } from 'redux-form';
import { combineReducers } from "redux";
import { headerReducer } from "./header";
import { profileReducer } from "./profile";
import { mainReducer } from "./main";
import { modalReducer } from "./modal";
import { menuReducer } from "./menu";
import { personalReducer } from "./personal";
import { myOrdersReducer } from "./myOrders";
import { addCompany } from "./addCompany"

export const rootReducer = combineReducers({
  header: headerReducer,
  profile: profileReducer,
  main: mainReducer,
  modal: modalReducer,
  menu: menuReducer,
  personal: personalReducer,
  form: formReducer,
  myOrders: myOrdersReducer,
  addCompany: addCompany,
});
